import { Routes, Route } from 'react-router-dom'
import { Dashboard } from '../pages/dashboard'
import { Howtouse } from '../pages/howtouse'
import { About } from '../pages/about'
import { NotFound } from '../pages/notfound'

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/howtouse" element={<Howtouse />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  )
}
