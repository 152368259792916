import { useState, useContext } from 'react'
import { client } from '../libs/axios'
import { TweetContext } from '../provider/TweetProvider'

import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import { TextField } from '@material-ui/core'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import TwitterIcon from '@mui/icons-material/Twitter'

import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'

import Switch from '@mui/material/Switch'

export const InputForm = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))

  return (
    <>
      <CssBaseline />
      <Stack
        spacing={2}
        sx={{ marginTop: 3, marginBottom: 3, boxShadow: 3, borderRadius: 1 }}
      >
        <Item sx={{ bgcolor: 'rgb(249 251 253)' }}>
          <FormArea />
        </Item>
      </Stack>
    </>
  )
}

export const FormArea = () => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [RemoteWork, setRemoteWork] = useState(true)
  const [selectedBtn, setSelectedBtn] = useState(1)

  const onChangeSearchKeyword = (event: any) => {
    setSearchKeyword(event.target.value)
  }

  const onChangeRemoteWork = (event: any) => {
    setRemoteWork(event.target.checked)
  }

  const { setTweets } = useContext(TweetContext)

  const searchTweet = async () => {
    const searchParams = {
      keyword: searchKeyword,
      kind: selectedBtn,
      remote: RemoteWork,
    }

    await client
      .get('redirect.php', { params: searchParams })
      .then(({ data }) => {
        setTweets(data)
      })
  }

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-3155085421226594"
        data-ad-slot="8011667714"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      <div className="search-inputform">
        <TextForm
          value={searchKeyword}
          handler={onChangeSearchKeyword}
          onclick={searchTweet}
        />
      </div>
      <div>
        <SwitchForm checked={RemoteWork} handler={onChangeRemoteWork} />
        <ButtonForm selected={selectedBtn} handler={setSelectedBtn} />
        <div>
          <Button
            variant="contained"
            size="large"
            onClick={searchTweet}
            className="mt-xl-5"
            startIcon={<TwitterIcon />}
            sx={{ width: 300, marginTop: 4 }}
          >
            検索
          </Button>
        </div>
      </div>
    </>
  )
}

const TextForm = (props: any) => {
  return (
    <TextField
      id="standard-size-normal"
      label="キーワード"
      type="search"
      variant="standard"
      onChange={props.handler}
      name="keyword"
      value={props.value}
      helperText="例 東京 ライター"
      fullWidth
    />
  )
}

const SwitchForm = (props: any) => {
  const RemoteWorkSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }))

  return (
    <FormControlLabel
      control={<RemoteWorkSwitch />}
      label="リモートワーク"
      name="remote"
      checked={props.checked}
      onChange={props.handler}
      sx={{ marginLeft: 1 }}
    />
  )
}

const ButtonForm = (props: any) => {
  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          color={props.selected === 1 ? 'secondary' : 'primary'}
          onClick={() => props.handler(1)}
        >
          アルバイト
        </Button>
        <Button
          color={props.selected === 2 ? 'secondary' : 'primary'}
          onClick={() => props.handler(2)}
        >
          副業
        </Button>
        <Button
          color={props.selected === 3 ? 'secondary' : 'primary'}
          onClick={() => props.handler(3)}
        >
          フリーランス
        </Button>
      </ButtonGroup>
    </>
  )
}
