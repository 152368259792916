import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <>
      <div className="p-hero">
        <div className="p-hero__inner">
          <div className="p-hero__heading">
            <div className="p-hero__title">404 Error.</div>
            <div className="p-hero__subtitle">
              お探しのページはみつかりませんでした。
            </div>
          </div>
          <div className="p-hero__link">
            <a href="/" className="c-btn c-btn--transparent">
              HOME
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
