import {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { TweetType } from '../types'

interface PropType {
  children: ReactNode
}

interface ProviderType {
  tweets: TweetType[]
  setTweets: Dispatch<SetStateAction<TweetType[]>>
}

export const TweetContext = createContext<ProviderType>({} as ProviderType)

export const TweetProvider = (props: PropType) => {
  const { children } = props

  const [tweets, setTweets] = useState<TweetType[]>([])

  return (
    <TweetContext.Provider value={{ tweets, setTweets }}>
      {children}
    </TweetContext.Provider>
  )
}
