export const Howtouse = () => {
  return (
    <>
      <div className="p-sub-hero">
        <div className="p-sub-hero__inner">
          <h2 className="c-heading-primary c-heading-primary--white">
            HOW TO USE
            <span className="c-heading-primary__sub">使い方について</span>
          </h2>
        </div>
      </div>

      <div className="p-breadcrumb">
        <div className="l-inner">
          <ol
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            className="p-breadcrumb__list"
          >
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="p-breadcrumb__item"
            >
              <a itemProp="item" href="." className="p-breadcrumb__link">
                <span itemProp="name">HOME</span>
              </a>
              <meta itemProp="position" content="1" />
            </li>

            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="p-breadcrumb__item"
            >
              <span itemProp="item">
                <span itemProp="name">使い方</span>
              </span>
              <meta itemProp="position" content="2" />
            </li>
          </ol>
        </div>
      </div>

      <main className="l-contents">
        <section className="p-contact">
          <div className="l-inner">
            <div className="p-contact__heading">
              <h2 className="c-heading-primary">
                terms of service
                <span className="c-heading-primary__sub">利用規約</span>
              </h2>
            </div>
            <div className="p-contact__body">
              <div className="p-contact__text">
                <ul>
                  <li>
                    ユーザーは、当サービスを自身の責任と判断で利用するものとします。
                  </li>
                  <li>
                    当サービスを利用することにより発生したいかなる損害も、当方は責任を負わないものとします。
                  </li>
                  <li>
                    当サービスに表示するツイートに関する内容は当サービス・当方は責任を負わないものとします。
                  </li>
                  <li>
                    当サービスは、事前の予告なくサービス内容を変更、停止する場合があります。
                  </li>
                  <li>
                    上記の事項全てにご了承いただける場合のみ、当サービスをご利用ください。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
