import { useEffect, useContext } from 'react'
import { client } from '../libs/axios'
import moment from 'moment'
import 'moment/locale/ja'
import { TweetContext } from '../provider/TweetProvider'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const TweetList = () => {
  const { tweets, setTweets } = useContext(TweetContext)

  useEffect(() => {
    client.get('redirect.php').then(({ data }) => {
      setTweets(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))

  // console.log(tweets)
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} sx={{ marginTop: 3, marginBottom: 3 }}>
        {tweets.map((tweet) => {
          const tweetDate = moment(
            tweet.created_at,
            // 'ddd MMM DD hh:mm:ss ZZ YYYY'
            'mm/dd hh:mm'
          )
          let user_url = 'https://twitter.com/' + tweet.screen_name + '/'
          let full_text = tweet.full_text
          // console.log(tweet)

          return (
            <Item key={tweet.id}>
              <div className="tw-header-area">
                <div className="tw-profile-icon-area">
                  <a href={user_url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={tweet.image_url}
                      className="tw-profile-icon"
                      alt={tweet.screen_name}
                    />
                  </a>
                </div>
                <div className="tw-profile-name-area">
                  <a
                    href={tweet.tweet_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="tw-profile-name">{tweet.name}</div>
                  </a>
                  <div className="tw-profile-date">
                    {tweetDate.add(9, 'h').format('MM/DD HH:mm')}
                  </div>
                </div>
              </div>
              <div className="tw-text-area">
                {full_text.split('\n').map((str, index) => (
                  <div key={index}>{str}</div>
                ))}
              </div>
              <div className="tw-more-area">
                <a
                  href={tweet.tweet_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  more...
                </a>
              </div>
            </Item>
          )
        })}
      </Stack>
    </Box>
  )
}
