export const About = () => {
  return (
    <>
      <div className="p-sub-hero">
        <div className="p-sub-hero__inner">
          <h2 className="c-heading-primary c-heading-primary--white">
            ABOUT
            <span className="c-heading-primary__sub">本サービスについて</span>
          </h2>
        </div>
      </div>

      <div className="p-breadcrumb">
        <div className="l-inner">
          <ol
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            className="p-breadcrumb__list"
          >
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="p-breadcrumb__item"
            >
              <a itemProp="item" href="." className="p-breadcrumb__link">
                <span itemProp="name">HOME</span>
              </a>
              <meta itemProp="position" content="1" />
            </li>

            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="p-breadcrumb__item"
            >
              <span itemProp="item">
                <span itemProp="name">ABOUT</span>
              </span>
              <meta itemProp="position" content="2" />
            </li>
          </ol>
        </div>
      </div>

      <main className="l-contents">
        <section className="p-about l-section">
          <div className="l-inner">
            <div className="p-about-info__heading">
              <h2 className="c-heading-primary">
                ABOUT TwiJob
                <span className="c-heading-primary__sub">
                  ツイジョブについて
                </span>
              </h2>
            </div>
            <div className="p-about__body">
              <div className="p-terms__text">
                <ul>
                  <li>ツイジョブとは</li>
                  <li>
                    仕事探しの妨げになるツイートや広告を極力除外した、集中してTwitterで仕事探しのできる検索エンジンです。
                  </li>
                  <li>
                    違法性の高い求人は掲載しないように努めており掲載最新7日間のツイートを50件まで表示しています。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="p-terms">
          <div className="l-inner">
            <div className="p-terms__heading">
              <h2 className="c-heading-primary">
                terms of service
                <span className="c-heading-primary__sub">利用規約</span>
              </h2>
            </div>
            <div className="p-terms__body">
              <div className="p-terms__text">
                <ul>
                  <li>
                    ユーザーは、当サービスを自身の責任と判断で利用するものとします。
                  </li>
                  <li>
                    当サービスを利用することにより発生したいかなる損害も、当方は責任を負わないものとします。
                  </li>
                  <li>
                    当サービスに表示するツイートに関する内容は当サービス・当方は責任を負わないものとします。
                  </li>
                  <li>
                    当サービスは、事前の予告なくサービス内容を変更、停止する場合があります。
                  </li>
                  <li>
                    上記の事項全てにご了承いただける場合のみ、当サービスをご利用ください。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="p-company-info l-section">
          <div className="l-inner">
            <div className="p-company-info__heading">
              <h3 className="c-heading-secondary">運営概要</h3>
            </div>
            <div className="p-company-info__table">
              <table className="company_table">
                <tbody>
                  <tr>
                    <th>運営</th>
                    <td>HertzGear（ヘルツギア）</td>
                  </tr>
                  <tr>
                    <th>サイトURL</th>
                    <td>
                      <a
                        href="https://hertzgear.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://hertzgear.com/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>代表</th>
                    <td>蓮尾　斉</td>
                  </tr>
                  <tr>
                    <th>お問い合わせ</th>
                    <td>
                      <a href="mailto:h.hasui0310@gmail.com">
                        h.hasui0310@gmail.com
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
