import { InputForm } from '../components/InputForm'
import { TweetList } from '../components/TweetList'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'

export const Dashboard = () => {
  return (
    <>
      <div className="p-hero">
        <div className="p-hero__inner">
          <div className="p-hero__heading">
            <div className="p-hero__title">集中できるTwitter仕事探し</div>
            <div className="p-hero__subtitle">ツイジョブサーチ</div>
          </div>
          <div className="p-hero__link">
            <a href="#search_area" className="c-btn c-btn--transparent">
              仕事を探す
            </a>
          </div>
        </div>
        <span className="p-hero__scroll">SCROLL</span>
      </div>

      <CssBaseline />

      <main className="l-contents" id="search_area">
        <section className="p-contact">
          <div className="l-inner">
            <Container maxWidth="lg">
              <InputForm />

              <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-3155085421226594"
                data-ad-slot="8011667714"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>

              <TweetList />
            </Container>
          </div>
        </section>
      </main>
    </>
  )
}
